import React, { useState, useEffect } from 'react'
import './FormSheet.css'
import Sheet from './Sheet'

function FormSheet({children, variant, active, dismiss}) {

  return (
    <>
      <div className={`form-sheet${active ? ' active' : ''}`}>
        <Sheet variant={'form'}>
          {children}
        </Sheet>
        <div className='overlay' onClick={dismiss}></div>
      </div>
    </>
  )
}

export default FormSheet