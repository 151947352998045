import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Tag, Button, Group, HStack, Section, Content, Container, VStack, Space, Divider, Loader, Image, Title1, Title2, Title3, Headline, Body, Subhead, Footnote, Caption1, Caption2, Icon } from '@themeable/react-components'
import Blockquote from '../Patterns/Blockquote';
import GlobalFooter from '../Patterns/GlobalFooter'


function BlogEntry({ globals, setGlobals }) {
    const[loading, setLoading] = useState(true);
    const { param } = useParams();
	const[pageTitle, setPageTitle] = useState('');
	const[blocks, setBlocks] = useState('');
	const [tags, setTags] = useState('');

	useEffect(() => {
		setGlobals((prev) => ({
			...prev,
			page: '',
			pageColor: '--color-background-elevated'
		})); 
		console.log(param)
	}, [])
	
	
    useEffect(() => {
		fetch(`/api/blog/${param}`)
		.then((res) => res.json())
		.then((res) => {
			setPageTitle(res.Name)
			setBlocks(res.Blocks)
			setLoading(false)
			setTags(res.Tags)
		})
		.then((res) => { 
			setGlobals((prev) => ({
				...prev,
				blogs: res
			})); 
		})
		.catch((error) => {	console.error('Error fetching data: ', error); });}, 
	[]);

	const imageMap = {
		'##1': 'path/to/image1.jpg',
		'##2': 'path/to/image2.jpg',
		// ... up to '##20'
		'##20': 'path/to/image20.jpg',
	  };

return (
    <>
	{/* Illustration */}
	{loading ? <></>:
	<HStack style={{position: 'relative', left: '0px', top: '0px'}} showOn='desktop'>
		<div style={{position: 'absolute', transform: 'translateX(-50%) rotate(45deg)'}}>
			<Icon 
				name='Pattern2'
				svgPath='../Illustrations.svg'
				size='256px'
				color='--color-accent'
				/>
		</div>
	</HStack>
	}
	


	<Section>
	<HStack gridDesktop={2} gridTablet={2} gridMobile={1}>
	
	{/* Left column */}
	<HStack>
		<VStack style={{position: 'relative'}} layout='top'>
			<HStack>
				<Group>
					{tags && (tags.map((tag, index) => (
						<Tag key={index} text1={tag} />
					)))}
				</Group>
			</HStack>
			<Title1>
				{pageTitle}
			</Title1>
			
			<Divider />
			<HStack layout='middle'>
				<Image
					profile
					url='../assets/redeeming-special-needs-profile-jessica-kounter.jpg'
					caption='Image of Jessica'
					size='medium'
					/>
				<VStack layout='top left'>
					<Group>
						<Headline>Jessica Kounter</Headline>
						<Subhead>RN, BSN, CPN  |  Pediatric Nurse & Doula</Subhead>
						<Footnote>Franklin, TN</Footnote>
					</Group>
					<Button variant='tertiary' small text1='Learn more' url='../about' />
				</VStack>
			</HStack> 

			<Divider />

			<Subhead>Jump to topic:</Subhead>
			{loading ? <>
			<Button loading small variant='secondary'/>
			<Button loading small variant='secondary'/>
			</> : <>
				{blocks.map((block, index) => (
					<>
						{(() => {
							switch (block.type) {
							case 'heading_1': return ( 
								<>
									{block.heading_1.rich_text[0].plain_text.includes('##') ? (
										<Button 
											icon1={block.heading_1.rich_text[0].plain_text.substring(2, 4).trim()}
											svgPath='../Illustrations.svg'
											url={`#${block.heading_1.rich_text[0].plain_text.substring(4).toLowerCase().trim().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`} 
											text1={block.heading_1.rich_text[0].plain_text.substring(3)} />
									) : (
										<>
										<Button 
											url={`#${block.heading_1.rich_text[0].plain_text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`} 
											text1={block.heading_1.rich_text[0].plain_text} />
										</>
									)}
								</>
							)
							break;
							case 'heading_2': return (  
								<>
								{block.heading_2.rich_text[0].plain_text.includes('##') ? (
								<HStack>
									<Space.Content />
									<Space.Content />
									<Button 
										icon1={block.heading_2.rich_text[0].plain_text.substring(2, 4).trim()}
										svgPath='../Illustrations.svg'
										url={`#${block.heading_2.rich_text[0].plain_text.substring(4).toLowerCase().trim().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`} 
										text1={block.heading_2.rich_text[0].plain_text.substring(3)} />
								</HStack>
								) : (
									<>
									<Button 
										url={`#${block.heading_2.rich_text[0].plain_text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`} 
										text1={block.heading_2.rich_text[0].plain_text} />
									</>
								)}
							</>
							)
							break;
							default: return ''
							break;
						}
						})()}
					</>
				))}
			</>}
			<Space.NewSection />
			
			{/* Illustration */}
			{loading ? <></>:<>
			
			<Space.Grow />
			<HStack style={{position: 'relative', left: '0px', top: '800px'}} showOn='desktop tablet'>
				<div style={{position: 'absolute', left: '0px', transform: 'translate(-50%, -100%) rotate(-40deg)', opacity: '.5'}}>
					<Icon 
						name='Pattern1'
						svgPath='../Illustrations.svg'
						size='200px'
						color='--color-accent'
						/>
				</div>
			</HStack>
			</>
			}
		</VStack>
		<HStack showOn='desktop tablet'>
			<Space.NewSection />
			<Divider vertical />
			<Space.NewSection />
		</HStack>
	</HStack>

	{/* Right column */}

	<VStack>
		<HStack showOn='mobile'><Divider /><Space.NewSection /></HStack>
	{loading ? <></> : <>
	{blocks.map((block, index) => (
		<div key={index}>
		
		{/* <Caption1>
			{JSON.stringify(block.type, null, 2)}
		</Caption1> */}
			
      	{(() => {
		switch (block.type) {
		case 'paragraph': return (  
			<Body tag='p'>
			{block.paragraph.rich_text.map((chunk, index) => (
				<>
				{chunk.href ? (
					<a 
						style={{color: 'rgba(var(--color-primary))'}}
						href={chunk.href}
						target={chunk.href.includes('localhost') || chunk.href.includes('redeemingspecialneeds.com') ? undefined : '_blank'}
						rel={chunk.href.includes('localhost') || chunk.href.includes('redeemingspecialneeds.com') ? undefined : 'noopener noreferrer'}
					>
						{chunk.plain_text}
					</a>
				) : chunk.annotations?.bold ? (
					<span>
						<strong>
							{chunk.plain_text}
						</strong>
					</span>
				):chunk.annotations?.italic ? (
					<span>
						<em>
							{chunk.plain_text}
						</em>
					</span>
				):(
					<>
						<span>
							{chunk.plain_text}
						</span>
					</>
					)}
			</>
			))}
			</Body>  
		)
		break;
		case 'heading_1': return ( 
			<>
				<Space.NewSection />
				{block.heading_1.rich_text[0].plain_text.includes('##') ? (
				<span id={block.heading_1.rich_text[0].plain_text.substring(3).trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
				<HStack layout='middle'>
					<Icon 
					name={block.heading_1.rich_text[0].plain_text.substring(2, 4).trim()} 
					svgPath='../Illustrations.svg' 
					color='--color-accent'
					size='80px'
					/>
					<Title2 tag='h2'>
					{block.heading_1.rich_text[0].plain_text.substring(3)}
					</Title2>
				</HStack>
				</span>
				) : (
					<span id={block.heading_1.rich_text[0].plain_text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
						<Title2 tag='h2'>
							{block.heading_1.rich_text[0].plain_text}
						</Title2>
					</span>
				)}
			</>
		)
		break;
		case 'heading_2': return (  
			<>
				<Space.NewSection />
				{block.heading_2.rich_text[0].plain_text.includes('##') ? (
				<span id={block.heading_2.rich_text[0].plain_text.substring(3).trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
				<HStack layout='middle'>
					<Icon 
					name={block.heading_2.rich_text[0].plain_text.substring(2, 4).trim()} 
					svgPath='../Illustrations.svg' 
					color='--color-accent'
					size='64px'
					/>
					<Title2 tag='h2'>
					{block.heading_2.rich_text[0].plain_text.substring(3)}
					</Title2>
				</HStack>
				</span>
				) : (
					<span id={block.heading_2.rich_text[0].plain_text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
						<Title3 tag='h3'>
							{block.heading_2.rich_text[0].plain_text}
						</Title3>
					</span>
				)}
			</>
		)
		break;
		case 'heading_3': return (  
			<span id={block.heading_3.rich_text[0].plain_text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
				<Headline tag='h4'>
					{block.heading_3.rich_text[0].plain_text}
				</Headline>
			</span>
		)
		break;
		case 'quote': return (  
				<Blockquote>
				{block.quote.rich_text[0].plain_text}
				</Blockquote>
		)
		break;
		case 'divider': return (  
			<Divider />
			)
		break;
		case 'callout': return (  
			<Container variant='fill'>
			<Section>
			<HStack>
					<Body>
					{block.callout.icon.emoji}
					</Body>
					<Body tag='h5'>
					{block.callout.rich_text[0].plain_text}
					</Body>
			</HStack>
			</Section>
			</Container>
		)
		break;
		case 'image': return (  
			<>
			<Image 
				caption={block.image.caption[0].plain_text}
				url={block.image.file.url}
				showCaption
			/>
			</>	
			)
		break;
		case 'numbered_list_item': return (  
			<>
			<Body>
				<span>- {block.numbered_list_item.rich_text[0].plain_text}
				</span>
			</Body>
			</>	
			)
		break;
		case 'bulleted_list_item': return (  
			<>
			<Body>
				<span>- {block.bulleted_list_item.rich_text[0].plain_text}
				</span>
			</Body>
			</>	
			)
		break;
		default: return ''
		break;
	}
})()}
   	 	</div>
  	))}
	</>}
	</VStack>
	</HStack>
	</Section>
	</>
)
}

export default BlogEntry