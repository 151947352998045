import { Section, VStack, HStack, Title3, Body, Space, Tab, Button, Subhead, Popover, Headline, Divider } from '@themeable/react-components'
import React, { useEffect, useState, useRef } from 'react'
import './Navbar.css'
import { Logo, LogoShort } from './Logo';
import FormSheet from '../Pages/temp-ts/FormSheet'
import Input from '../Pages/temp-ts/Input'
import { collection, addDoc, Timestamp, arrayUnion, updateDoc, doc, addField } from "firebase/firestore";
import { db } from '../services/firebase';


function Navbar({ setGlobals, globals }) {
	const [scrollPosition, setScrollPosition] = useState(0);
	const navbarRef = useRef('--color-positive');
	// const [isFormSheetActive, setIsFormSheetActive] = useState(false);
	const toggleFormSheet = () => {
	  setGlobals((prev) => ({
		...prev,
		sayHelloSheet: !globals.sayHelloSheet
	})); 
	};

	useEffect(() => {

		// window.addEventListener('scroll', () => setScrollPosition(window.scrollY));

		if (navbarRef.current) {
		  	const height = navbarRef.current.clientHeight;
			setGlobals((prev) => ({
				...prev,
				// navHeight: height
				navHeight: '56px'
			})); 
		}

	  }, []);

	  const [formSuccess, setFormSuccess] = useState(false)
	  const [name, setName] = useState('')
	  const [email, setEmail] = useState('')
	  const [message, setMessage] = useState('')
	  const [errorMessage, setErrorMessage] = useState('')

	  const sendHello = () => {

        const isValidName = (name) => name && /^[a-zA-Z-' ]+$/.test(name);

        // Email validation using a regular expression
        const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

        if (!isValidName(name)) {
            console.error('Invalid name');
            setErrorMessage('Invalid name');
            return;
        }

        if (!isValidEmail(email)) {
            console.error('Invalid email');
            setErrorMessage('Invalid email');
            return;
        }

        const encodedEmail = email.replace(/\./g, ',');

        updateDoc(doc(db, 'subscriptions', 'say-hello'), {
            [encodedEmail]: {
                name: name,
                status: 'active',
                lastUpdated: Timestamp.now(),
                source: window.location.href,
				message: message
            }
        })
        .then(() => {
            setFormSuccess(true);
        })
        .catch((error) => {
            setErrorMessage(error.message);
        });
    };

  return (
    <>
		<nav 
		className={`navbar ${scrollPosition > 0 ? 'sticky' : ''}`} ref={navbarRef}
		style={{background: `rgba(var(${globals.pageColor}), .8)`}}>
			<VStack>
				<Section style={{paddingTop: '0px', paddingBottom: '0px'}}>
					<HStack layout='middle'>
						<LogoShort width='80px' />
						<Space.Grow/>

						<HStack className='navTabs' showOn='desktop tablet' layout='right'>
							<Tab 
								text1='Home' url='../' type='link' indicator='bottom'
								active={ globals.page === 'home' ? true : false }/>
							<Tab 
								text1='Blog' url='../blog' type='link' indicator='bottom'
								active={ globals.page === 'blog' ? true : false }/>
							<Tab 
								text1='About' url='../about' type='link' indicator='bottom'
								active={ globals.page === 'about' ? true : false }/>
							<Button variant='secondary' text1='Say Hello' onClick={() => {toggleFormSheet()}}/>
						</HStack>
						<HStack showOn='mobile' layout='right'>
						<Popover trigger={
							<Button className='navHamburger' icon1='Hamburger' variant='quaternary'/>
						}>
							<Section>
								<VStack>
									<Tab 
										text1='Home' url='../' type='link' indicator='none'
										active={ globals.page === 'home' ? true : false }/>
									<Tab 
										text1='Blog' url='../blog' type='link' indicator='none'
										active={ globals.page === 'blog' ? true : false }/>
									<Tab 
										text1='About' url='../about' type='link' indicator='none'
										active={ globals.page === 'about' ? true : false }/>
									<Divider />
									<Button 
										text1='Say Hello' 
										onClick={() => {toggleFormSheet()}}
										/>
								</VStack>
							</Section>
						</Popover>
						</HStack>
						
					</HStack>
					<Divider nested/>
				</Section>
			</VStack>
		</nav>

		<FormSheet variant="someVariant" active={globals.sayHelloSheet} dismiss={() => {toggleFormSheet()}}>
		<Section>
			<HStack gridDesktop={3} gridMobile={3} gridTablet={3}>
				<div />
				<VStack layout='center middle'>
					<Headline>
						Say Hello
					</Headline>
				</VStack>
				<VStack layout='right'>
					<Button small variant='secondary' icon1='Close' onClick={() => {toggleFormSheet()}}/>
				</VStack>
			</HStack>
			{formSuccess ? (<>
			<Title3>
				Thanks!
			</Title3>
			<Body>
				I'm looking forward to reading your message.
			</Body>
			</>):(<>
			<Title3>
				Hi!
			</Title3>
			<Body>
			I’m excited to connect with you. You can fill out the form below or contact me directly via email: hello@redeemingspecialneeds.com.
			</Body>
			<Space.Content />
			<Space.Content />
			<form>
				<HStack>

						<VStack>
							<Subhead style={{minWidth: '120px', marginBottom: '0px'}}>
								Name
							</Subhead>
							<Input variant='fill' onChange={((e) => {setName(e)})}/>
						</VStack>
						<VStack>
							<Subhead style={{minWidth: '120px', marginBottom: '0px'}}>
								Email
							</Subhead>
							<Input variant='fill' onChange={((e) => {setEmail(e)})}/>
						</VStack>
						
				</HStack>

				<VStack>
					<Subhead style={{minWidth: '120px', marginBottom: '0px'}}>
						Message
					</Subhead>
					<Input variant='fill' onChange={((e) => {setMessage(e)})}/>
				</VStack>

				<HStack layout='right middle'>
					<Subhead color='--color-negative'>{errorMessage}</Subhead>
					<Space.Content />

					<Button 
						onClick={() => {toggleFormSheet()}} 
						text1='Cancel'
						/>
					<Button 
						 
						type='submit' 
						onClick={((e) => {sendHello()})} 
						variant='primary'
						text1='Send'
						/>
				</HStack>
			</form>
			</>)}
		</Section>
	</FormSheet>
    </>
  )
}

export default Navbar