import React from 'react'
import { Section, Title1, HStack, Image, VStack, Body, Headline, Icon, Space } from '@themeable/react-components'
import { Logo } from '../../Patterns/Logo'

function Hero() {
  return (
    <div style={{background: 'rgb(var(--color-background-flat))', overflow: 'hidden'}}>
		<Section>
			<HStack gridDesktop={2} gridTablet={2} gridMobile={1} layout='middle'>
			
				<VStack style={{position: 'relative'}} layout='center middle'>
					<Space.NewSection />
					<Space.NewSection />
					<Title1 style={{ zIndex: '2'}}>About</Title1>
					<span style={{ zIndex: '2'}}>
						<Logo width='300px'/>
					</span>
					<Space.NewSection />
					<Space.NewSection />

					<span style={{opacity: '.1', position: 'absolute', width: '100%', height: '100%', animation: 'spin 300s linear infinite'}}>
						<Icon
							name='PatternFlower' 
							svgPath='../Illustrations.svg'
							size='100%'
							color='--color-accent'
							/>
						</span>
					<span style={{opacity: '.1', position: 'absolute', width: '80%', height: '80%', animation: 'spin 300s linear infinite', animationDirection: 'reverse'}}>
						<Icon
							name='PatternFlower' 
							svgPath='../Illustrations.svg'
							size='100%'
							color='--color-accent'
							/>
						</span>
					<span style={{opacity: '.1', position: 'absolute', width: '150%', height: '150%', animation: 'spin 400s linear infinite', animationDirection: 'reverse'}}>
						<Icon
							name='PatternFlower' 
							svgPath='../Illustrations.svg'
							size='100%'
							color='--color-accent'
							/>
						</span>
				</VStack>
				<VStack layout='center'>
					<Headline align='center' style={{maxWidth: '400px', zIndex: '2'}}>
					Redeeming special needs came out of a word the Lord gave me to redeem. Redeem has been a common thread throughout my life...
					</Headline>
				</VStack>
			</HStack>
		</Section>
    </div>
  )
}

export default Hero