import React from 'react'
import { Section, HStack, Title3, VStack, Group, Body, Space, Icon, Subhead, Headline, Caption1 } from '@themeable/react-components'

function Faith({pageMargins}) {

  return (
    <>
    <Section style={{maxWidth: pageMargins}}>
        <HStack gridDesktop={2} gridMobile={1} gridTablet={2}>
            <HStack>

                <VStack>
                    <Group>
                        <Title3 tag='span'>God put this ministry of redeeming special needs children on my heart because they are the most precious gifts from above! <Title3 tag='span' color='--color-accent'>The stigma and negativity around children with special needs has GOT TO GO.</Title3></Title3>
                    </Group>
                </VStack>
                <Space.NewSection />
            </HStack>
            <VStack>
                <Body>
                    If you grew up in the church you may have heard this bible verse from James 1:17 before: <strong>
                    “Every good and perfect gift is from above, coming down from the Father of the heavenly lights, who does not change like shifting shadows.”</strong>
                </Body>
                <Body>
                    Whether this is the first time or 100th time you’ve heard it-it applies to your children and your family. YOU are a good and perfect gift from above. Your CHILDREN are a good and perfect gift from above. Having a child with special needs is something we can grieve and celebrate at the same time!
                </Body>
                <Body>
                    Do you want to know what’s even more celebratory? The fact that God CHOSE you to be your child’s mama. It was by no accident. I wrestled with this for a painfully long time. How could God choose me? I believed the lie that I am weak, impatient, incapable, fearful, anxious and therefore not fit to parent a child with special needs. Let alone 2!
                </Body>
                <Body>
                    But the more I fought that I was CHOSEN the more God showed me that He did indeed choose me. A verse that I’ve clung to when I’m unsure of my purpose with my children is found in Ephesians 1:11-12 (MSG version). I’m putting the message version here because it’s too good!
                </Body>
            </VStack>
        </HStack>
    </Section>

    <Section style={{maxWidth: pageMargins}}>
    

        <VStack style={{overflow: 'hidden'}}>

        <Section style={{position: 'relative'}}>
            <VStack layout='center'>
                <VStack style={{position: 'absolute', width: '100%', height: '100%', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
                    <Icon
                        name='PatternBackground' 
                        svgPath='../Illustrations.svg'
                        color='--color-primary'
                        size='100%'
                        />
                </VStack>
                <VStack style={{maxWidth: '600px'}} layout='center' grow>
                    <Space.NewSection />
                    <Caption1>Ephesians 1:11-12 (MSG version)</Caption1>
                    <Title3 style={{zIndex: '2'}} align='center'>
                    “It’s in Christ that we find out who we are and what we are living for. Long before we first heard of Christ and got our hopes up, He had his eye on us, had designs on us for glorious living, part of the overall purpose he is working out in everything and everyone.”
                    </Title3>
                    <Space.NewSection />
                </VStack>
            </VStack>
        </Section>

        </VStack>


    </Section>

    <Section style={{maxWidth: pageMargins}}>
        <Body>
            God’s word never ceases to amaze me. Redemption is here because both you and your child were intentionally designed for God’s glory. There is purpose in your life and the beautiful life of your child!
        </Body>
    </Section>
    </>
  )
}

export default Faith