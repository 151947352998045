import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet'

// Pages
import Home from './Pages/Home/Home';
import Blog from './Pages/Blog';
import BlogEntry from './Pages/BlogEntry';

import DadasArm from './Pages/DadasArm';
import NotFoundPage from './Pages/NotFoundPage';

// Components
import Navbar from './Patterns/Navbar';
import GlobalFooter from './Patterns/GlobalFooter'
import { VStack } from '@themeable/react-components';
import About from './Pages/About/About';

function App() {
	const [globals, setGlobals] = useState({
	  blogs: null,
	  mode: 'this is a test',
	  page: null,
	  navHeight: '60px',
	  pageColor: "--color-background-flat", // Default color
	  sayHelloSheet: false
	});



	return (
		<>
		<Helmet>
        	<meta name="theme-color" content={globals.pageColor} />
      	</Helmet>
		<VStack style={{minHeight: '100vh', background: `rgb(var(${globals.pageColor}))`}}>
			<Navbar globals={globals} setGlobals={setGlobals}/>
			<main style={{paddingTop: globals.navHeight}}>
				{console.log(globals)}
				<Router>
					<Routes>
						<Route 
							path='/' 
							element={<Home globals={globals} setGlobals={setGlobals}/>} 
							/>
						<Route 
							path='/blog' 
							element={<Blog globals={globals} setGlobals={setGlobals}/>} 
							/>
						<Route 
							path='/blog/:param' 
							element={<BlogEntry globals={globals} setGlobals={setGlobals}/>} 
							/>
						<Route 
							path='/about' 
							element={<About setGlobals={setGlobals} />} 
							/> 
						<Route 
							path='/dadasarm' 
							element={<DadasArm />} 
							/> 
						<Route 
							path='*' 
							element={<NotFoundPage />} 
							/> 
					</Routes>
				</Router>
			</main>
			<GlobalFooter />
		</VStack>
		</>
	);
}

export default App;
