import React, { useEffect } from 'react';
import { } from '@themeable/react-components'
import Hero from './Hero'
import Overview from './Overview'
import Faith from './Faith'
import Family from './Family'
import Outro from './Outro'

import { Divider, Section, HStack, VStack, Title3, Body, Space } from '@themeable/react-components'

function About({ setGlobals }) {
    
useEffect(() => {
    setGlobals((prev) => ({
        ...prev,
        page: 'about',
        pageColor: '--color-background-elevated'
    })); 
}, [])

const pageMargins = '1000px'
    
return (<>

<Hero />

<Overview pageMargins={pageMargins} />

<Section style={{maxWidth: pageMargins}}>
    <Divider />
</Section>

<Faith pageMargins={pageMargins} />

<Section style={{maxWidth: pageMargins}}>
    <Divider />
</Section>

<Section style={{maxWidth: pageMargins}}>
    <HStack gridDesktop={2} gridMobile={1} gridTablet={2}>
        <HStack>
            <Title3 color='--color-accent'>Redemption for special needs families looks like:</Title3>
            <Space.NewSection />
        </HStack>
        <VStack>
            <Body tag='span'>
                <ul>
                    <li>strong marriages that beat the 87% divorce rate</li>
                    <li>children that are cherished for who they are</li>
                    <li>supportive community lifting families up</li>
                    <li>confidence in your ability to care for your child regardless of their challenges</li>
                    <li>faith that your child’s story has been intentionally written</li>
                    <li>health and wellness for the whole family</li>
                    <li>home filled with peace rather than overwhelm</li>
                </ul>
            </Body>
            <Body>
                …the list is endless! Our redemption stories may look different, but God is working in both of us. We’re in this together sister!
            </Body>
        </VStack>
    </HStack>
</Section>

<Section style={{maxWidth: pageMargins}}>
    <Divider />
</Section>

<Family pageMargins={pageMargins} />

<Section style={{maxWidth: pageMargins}}>
    <Divider />
</Section>

<Outro pageMargins={pageMargins} setGlobals={setGlobals} />

<Space.NewSection />

</>)}

export default About