import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Group, Tag, Button, HStack, Section, Container, VStack, Space, Divider, Loader, Image, Title1, Title2, Title3, Headline, Body, Subhead, Footnote, Caption1, Caption2, Icon } from '@themeable/react-components';
import GlobalFooter from '../Patterns/GlobalFooter'

function Blog({ globals, setGlobals }) {
    const[loading, setLoading] = useState(true);
	const[filteredLayout, setFilteredLayout] = useState(false);

	useEffect(() => {
		setGlobals((prev) => ({
			...prev,
			page: 'blog',
			pageColor: '--color-background-elevated'
		})); 
	}, [])

	const [tagNames, setTagNames] = useState([]);
	useEffect(() => {
		if (globals.blogs != null) {
			setLoading(false);
			console.log(globals.blogs)
			const tags = globals.blogs.live.flatMap(blog =>
				blog.properties.Tags.multi_select.map(tag => tag.name)
			);
			const uniqueTags = [...new Set(tags)];
			setTagNames(uniqueTags);
		}
	}, [globals.blogs]);
	
	
    const filterBlogsBy = useCallback((criteria) => {
		fetch(`/api/filter-blogs/${criteria}`)
		.then((res) => res.json())
		.then((res) => {
			setGlobals((prev) => ({
				...prev,
				blogs: res
			})); 
			setFilteredLayout(true)
		})
	});	
    
    useEffect(() => {
		fetch('/api/all-blogs')
		.then((res) => res.json())
		.then((res) => { 
			setGlobals((prev) => ({
				...prev,
				blogs: res
			})); 
		})
		.catch((error) => {	console.error('Error fetching data: ', error); });}, 
	[]);

return (
    <>
		<VStack style={{overflow: 'hidden'}}>

			<HStack style={{position: 'relative'}} showOn='desktop tablet'>
				<div style={{position: 'absolute', transform: 'translateX(32px) rotate(0deg)', opacity: '.8'}}>
					<Icon 
						name='Pattern1'
						svgPath='../Illustrations.svg'
						size='200px'
						color='--color-primary'
						/>
				</div>
			</HStack>
			

			<VStack style={{background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.05)), rgb(var(--color-background-flat))`}}>
				<Space.NewSection/>
				<Section style={{maxWidth: '800px'}}>
					<HStack gridDesktop={2} gridMobile={1} gridTablet={2} layout='middle'>
						<HStack style={{minWidth: '350px'}} layout='center'>
							
							<Space.NewSection showOn='desktop tablet'/>
							<HStack style={{position: 'relative'}}>

								<div style={{position: 'absolute', 
								transform: 'rotate(45deg)', 
								left: '50%', 
								top: '50%',
								transform: 'translate(-50%, -50%)',
								opacity: '.1'
							}}>
									<Icon 
										name='PatternFlower'
										svgPath='../Illustrations.svg'
										size='100%'
										color='--color-primary'
										/>
								</div>

							<Title1 align='right'>The Blog</Title1>
							</HStack>
						</HStack>
						<Body color='--color-text-secondary'>
						Welcome to my blog! Here you can expect to find both personal and helpful posts. I’ll share my parts of my family’s story and also offer posts with REALLY practical tips for navigating the special needs world! 
						</Body>
					</HStack>
				</Section>
				<Space.NewSection/>
			</VStack>

			<HStack style={{position: 'relative'}} showOn='desktop tablet'>
				<div style={{position: 'absolute', right: '56px', bottom: '56px', transform: 'translate(50%, 50%) rotate(-45deg)',}}>
					<Icon 
						name='Pattern2'
						svgPath='../Illustrations.svg'
						size='200px'
						color='--color-accent'
						/>
				</div>
			</HStack>

		</VStack>

		{/* Topics tag/chip filter bar */}
		<Section>
			<HStack layout='top'>
				{loading ? (
				<>
				<Group>
					<Button variant='secondary' loading />
					<Button variant='secondary' loading />
					<Button variant='secondary' loading />
				</Group>
				</>
				) : (
				<>
					<HStack wrap layout='left middle'>
						<Subhead>
							Topics:
						</Subhead>
						{/* <Group> */}
							{tagNames.map((element, index) => (
								<Button key={index} small text1={element} variant='secondary' onClick={(() => filterBlogsBy(element))} />
								))}
						{/* </Group> */}
					</HStack>
				</>
				)}
			</HStack>
			<Divider />
		</Section>

		{filteredLayout ? (<>
		<Section>
			<HStack gridDesktop={6} gridTablet={4} gridMobile={2} layout='top'>
				{loading ? 
					<>
					<Loader />
					</> : <>
					{globals.blogs.live.map((blog, index) => (
					
					<Button variant='quaternary' url={`blog/${blog.properties.URL.url}`}>
						<VStack layout='left'>
							<Image 
								url={blog.properties.Cover.files[0]?.file.url} 
								caption={blog.properties.Cover.files[0]?.name}
								aspectRatio='4-3'
								/>
								<Headline>
									{blog.properties.Name.title[0]?.plain_text}
								</Headline>
							<Space.Content/>
							<HStack wrap>
								<Group>
									{blog.properties.Tags.multi_select.map((tag, index) => (
										<Tag text1={tag.name}/>
									))}
								</Group>
							</HStack>
						</VStack>
					</Button>    
					))}
				</>}
			</HStack>
		</Section>

		</>):(<>

		{/* Featured + 3 newest blogs */}
		<Section>
			{loading ? 
			<>
			<Loader/>
			</> : <>
			<HStack 
				gridDesktop={2} 
				gridTablet={2}
				gridMobile={1} >

				{/* Featured */}
				<HStack layout='top'>
					<Image 
						url={globals.blogs.favorite?.properties.Cover.files[0]?.file.url} 
						alt={globals.blogs.favorite?.properties.Cover.files[0]?.name} 
						size='large' aspectRatio='1-1'/>
						<Space.Content/>
					<VStack layout='top left'>
						<Subhead color='--color-text-secondary'>Featured</Subhead>
						<Title3 color='--color-primary'>{globals.blogs.favorite?.properties.Name.title[0].plain_text}</Title3>
						<Subhead color='--color-text-secondary'>{globals.blogs.favorite?.properties.Description?.rich_text[0].plain_text}</Subhead>
						<Space.Content/>
						<Button small text1='Read now' variant='tertiary' icon2='ChevronRight' url={`blog/${globals.blogs.favorite?.properties.URL.url}`}/>
					</VStack>
					<HStack showOn='desktop tablet'>
						<Space.NewSection/>
					</HStack>

				</HStack>
				<VStack showOn='mobile'>
					<Space.NewSection/>
					<Divider />
					<Space.NewSection/>
				</VStack>

				{/* Top 3 newest */}
				<HStack>
					<HStack showOn='desktop tablet'>
						<Divider vertical/> 
						<Space.NewSection/>
					</HStack>
					<VStack>
					{globals.blogs.live.slice(0, 3).map((blog, index) => (
						<>
						<Button variant='quaternary' url={`blog/${blog.properties.URL.url}`}>
							<HStack layout='top'>
								<VStack>
									<HStack>
										<Group>
											{blog.properties.Tags.multi_select.map((tag, index) => (
												<Tag text1={tag.name}/>
											))}
											</Group>
										</HStack>
										<Group>
											<Headline>{blog.properties.Name.title[0]?.plain_text}</Headline>
											<Footnote color='--color-text-secondary'>
												{blog.properties.Description?.rich_text[0].plain_text}
											</Footnote>
										</Group>
								</VStack>
								<VStack>
									<Image url={blog.properties.Cover.files[0]?.file.url}  size='medium' aspectRatio='4-3'/>
								</VStack>
							</HStack>
						</Button>
						<Divider/>
						</>
					))}
					</VStack>
				</HStack>
			</HStack>
			
			</>}
		</Section>


		<Section>
		<HStack gridDesktop={6} gridTablet={4} gridMobile={2} layout='top'>
			{loading ? 
			<>
			<Loader />
			</> : <>
			{globals.blogs.live.slice(3).map((blog, index) => (
			
			<Button variant='quaternary' url={`blog/${blog.properties.URL.url}`}>
				<VStack layout='left'>
					<Image 
						url={blog.properties.Cover.files[0]?.file.url} 
						caption={blog.properties.Cover.files[0]?.name}
						aspectRatio='4-3'
						/>
						<Headline>
							{blog.properties.Name.title[0]?.plain_text}
						</Headline>
					<Space.Content/>
					<HStack wrap>
						<Group>
							{blog.properties.Tags.multi_select.map((tag, index) => (
								<Tag text1={tag.name}/>
							))}
						</Group>
					</HStack>
				</VStack>
			</Button>    
			))}
		</>}
		</HStack>
		</Section>

		</>
		)}

	</>
)
}

export default Blog