// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage"


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDXXoHuxv000cs_LMnCHJUR1PzBgp59t_k",
    authDomain: "redeeming-special-needs.firebaseapp.com",
    projectId: "redeeming-special-needs",
    storageBucket: "redeeming-special-needs.appspot.com",
    messagingSenderId: "712834460266",
    appId: "1:712834460266:web:f1647be10a29aea6827813"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
// export const persistLocally = setPersistence(auth, auth.Auth.Persistence.LOCAL)
export default app;