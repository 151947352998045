// rfce
import React, { useEffect } from 'react'
import { Section, Title1 } from '@themeable/react-components'
function DadasArm() {

  return (
    <>
    <Section>








    Dadas arm page!!!



<br/>
<Title1>dadas hand</Title1>













    </Section>
    </>
  )
}

export default DadasArm;