// rfce
import React, { useEffect, useState } from 'react'
import { Logo } from '../../Patterns/Logo'
import { Group, Tag, Headline, Footnote, Image, Body, HStack, Title2, Title3, Title1, Icon, Section, Space, VStack, Divider, Container, Button } from '@themeable/react-components';
import { Quote } from './Quote'
import { SubscribeBanner } from '../SubscribeBanner';
import MeetTheFam from './MeetTheFam';

function Home({ globals, setGlobals }) {
	const[loading, setLoading] = useState(true);

	useEffect(() => {
		setGlobals((prev) => ({
			...prev,
			page: 'home',
			pageColor: '--color-background-elevated'
		})); 
	}, [])

	useEffect(() => {
		fetch('../api/all-blogs')
		.then((res) => res.json())
		.then((res) => { 
			setGlobals((prev) => ({
				...prev,
				blogs: res
			})); 
			setLoading(false);
		})
		.then((res) => {
			console.log(globals.blogs)
		})
		.catch((error) => {	console.error('Error fetching data: ', error); });}, 
	[]);
    

  return (
    <>
	<VStack style={{position: 'relative', background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.15)), rgb(var(--color-background-flat))`, overflow: 'hidden'}}>
		{/* Blob illustration */}
		<div style={{position: 'absolute', top: '0px', left: '0px'}}>
			<Icon
				name='PatternTopLeft' 
				svgPath='../Illustrations.svg'
				size="400px"
				/>
		</div>
		{/* Blob illustration */}
		<div style={{position: 'absolute', top: '0px', right: '0px'}}>
				<Space.Grow />
				<Icon
					name='PatternTopRight' 
					svgPath='../Illustrations.svg'
					size='233px'
					/>
			</div>
      	<Section>
        	<HStack gridDesktop={2} gridTablet={2} gridMobile={1} style={{zIndex: '2'}}>
				<HStack>
					<VStack>
						<Space.NewSection />
						<Space.NewSection />
						<Space.NewSection showOn='mobile' />
						<Space.NewSection showOn='mobile' />
						<Logo width={'312'}/>
						<Body>My name is Jessica and I’m a Certified Pediatric Nurse and mom to 2 amazing boys who have special needs. God gave me a heart and vision to encourage, equip, and educate mamas in the special needs club.</Body>
						<VStack showOn='desktop tablet'>
							<Space.NewSection />
							<Space.NewSection />
						</VStack>
					</VStack>
					<HStack showOn='desktop tablet'>
						<Space.NewSection />
					</HStack>
				</HStack>
				<VStack style={{position: 'relative'}}>
					<span style={{width: '100%', height: '100%', opacity: '.4', minHeight: '400px', animation: 'spin 500s linear infinite'}}>
						<Icon
							name='PatternFlower' 
							svgPath='../Illustrations.svg'
							size='100%'
							color='--color-primary'
							/>
					</span>
					<HStack layout="middle" style={{position: 'absolute', width: '100%', height: '80%', bottom: '0', transform: 'translateY(var(--space-v-section))'}}>
						<img src='../assets/redeeming-special-needs-meet-jessica.png' 
							style={{
								opacity: '.9',
								position: 'absolute',
								height: '100%',
								left: '50%',
								transform: 'translateX(-50%)'
								}}/>
					</HStack>
				</VStack>
        	</HStack>
      	</Section>
		<HStack style={{position: 'relative'}} showOn='desktop tablet'>
			{/* Blob illustration */}
			<div style={{position: 'absolute', bottom: '0px', left: '64px', width: '700px', height: '700px'}}>
				<Space.Grow />
				<Icon
					name='PatternBottom' 
					svgPath='../Illustrations.svg'
					size='700px'
					/>
			</div>
			
		</HStack>

	</VStack>
		
	<Section>

		<HStack gridDesktop={2} gridMobile={1} gridTablet={2} layout='middle'>
			<HStack>
				<HStack showOn='desktop tablet'>
					<Space.NewSection />
				</HStack>
				<VStack>
					<Space.NewSection />
					<Divider />
					<Title3 color='--color-accent'>Consider this the online HQ for all things a special needs parent wants to know!</Title3>
					<Divider />
					<Space.NewSection />
				</VStack>
					<Space.NewSection />
				<HStack showOn='desktop tablet'>
					<Space.NewSection />
				</HStack>
			</HStack>
			<VStack>
			<Space.NewSection showOn='mobile'/>
			<Space.NewSection showOn='mobile'/>

				<div className='custom-container'>
					<Container>
						<Section>
							<Body>
								I pulled together everything I’ve learned on the job as a pediatric nurse and from being a special needs parent. Instead of going to google, you can read through my blogs or listen to my podcast for the answers you’ve been searching for. 
							</Body>
							<Body>
								There’s no shame or judgment here, only tons of practical advice for navigating the world of loving a special needs child. I also added in a dash of encouragement for you mama, because you are SO deserving of being uplifted. Your job is beautiful, wonderful, messy, and also exceptionally challenging. 							</Body>
						</Section>
					</Container>
				</div>
			</VStack>
		</HStack>
		

	</Section>
	<VStack style={{position: 'relative'}} showOn='desktop'>
		<VStack style={{position: 'absolute', transform: 'translate(24px, -50%) rotate(0deg)'}}>
			<Icon
				name='Pattern1' 
				svgPath='../Illustrations.svg'
				size='200px'
				color='--color-accent'
				/>
		</VStack>
	</VStack>

	<Section style={{maxWidth: '10000px'}}>
		<Container variant='outline'>
		<Container variant='fill'>	
		<VStack style={{overflow: 'hidden'}}>

				<Section>
					<HStack gridDesktop={2} gridMobile={1} gridTablet={2} layout='middle'>
						<HStack>
							
							<VStack showOn='desktop tablet'>
								<Space.NewSection />
								<Space.NewSection />
							</VStack>
							<Title2 color='--color-accent'>
								My Top 3<br/>Fave Blog Posts
							</Title2>
							<VStack showOn='desktop tablet'>
								<Space.NewSection />
							</VStack>
						</HStack>
						<VStack layout='top'>
							{globals.blogs?.live?.slice(0, 3).map((blog, index) => (
								<>
								<Divider />
								<Button variant='quaternary' url={`blog/${blog.properties.URL.url}`}>
									<HStack>
										<VStack>
											<HStack>
												<Group>
													{blog.properties.Tags.multi_select.map((tag, index) => (
														<Tag text1={tag.name}/>
													))}
													</Group>
												</HStack>
												<Group>
													<Headline>{blog.properties.Name.title[0]?.plain_text}</Headline>
													<Footnote color='--color-text-secondary'>
														{blog.properties.Description?.rich_text[0].plain_text}...
													</Footnote>
												</Group>
										</VStack>
										<VStack style={{ position: 'relative', width: '100px !important', flexShrink: '0'}}>
											<span style={{position: 'absolute', width: '100%'}}>
												{/* <Image url={blog.properties.Cover.files[0]?.file.url} aspectRatio='4-3'/> */}
											</span>
										</VStack>
									</HStack>
								</Button>
								</>
							))}
							<Divider />
						</VStack>
					</HStack>
				</Section>
			</VStack>

		</Container>
		</Container>
	</Section>
	<Space.NewSection />
	<Quote />
	<Space.NewSection />
	<SubscribeBanner />
	<MeetTheFam />
    </>
  )
}

export default Home;