import React from 'react';
import { Image, Section, HStack, VStack, Title2, Body, Button, Space } from '@themeable/react-components'

function MeetTheFam() {
  return (
    <>
        <Section>
            <HStack gridDesktop={2} gridTablet={2} gridMobile={1} layout='middle'>
                <Image 
                    caption='image of my family'
                    url='../assets/redeeming-special-needs-family-photo.png'
                    aspectRatio='4-3'
                    align='top'
                    />
                    <VStack layout='center'>
                        <Space.NewSection />
                        <Space.NewSection />
                        <VStack style={{maxWidth: '400px'}}>
                            <Title2>
                                This is Us
                            </Title2>
                            <Body>
                                We love Jesus and eating gluten free mac n cheese for dinner.
                            </Body>
                            <Button variant='primary' text1='Get to Know Us' icon2='ArrowRight' url='../about' />
                        </VStack>
                        <Space.NewSection />
                        <Space.NewSection />
                    </VStack>
            </HStack>
        </Section>
    </>
  )
}

export default MeetTheFam