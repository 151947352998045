import React from 'react'
import { Image, Section, HStack, VStack, Title2, Body, Space, Title3, Caption1, Group } from '@themeable/react-components'

function Overview({pageMargins}) {

return (<>

<Section style={{maxWidth: pageMargins}}>
    <HStack gridDesktop={2} gridMobile={1} gridTablet={2} layout='middle'>
        <HStack>
            <VStack>
                <Title3 color='--color-accent'>It is painful to admit all of this, so stay with me!</Title3>
                <Body>
                    It has taken me almost 5 years of motherhood to embrace the redemption that is found in my children. With my medical background as a pediatric nurse I was afraid to face the children I had been blessed with. I didn’t know how to compartmentalize or “label” them. I did not want to embrace that both of my children have special needs. In fact, I wanted to run away from it! I was racked with guild and shame. I spent too much time pretending that they are “normal”. Doing all the “normal” things so that we don’t notice their differences.
                </Body>
                <Body>
                    Being special needs comes with stigmas on top of stigmas. Some that we believe ourselves because it has been so deeply engrained in us! I was playing into these stigmas and had a “woe is me” attitude.        
                </Body>
                <Space.NewSection showOn='mobile' />
            </VStack>
            <Space.NewSection />
        </HStack>

    <Image 
        url='../assets/redeeming-special-needs-family-collage.png'
        />
    </HStack>
    <Space.NewSection />
    <Title2 color='--color-accent' align='center'>
        Then I realized that just because my child has special needs does not mean:
    </Title2>
    <Space.Content />
    <Space.Content />
    <HStack gridDesktop={4} gridTablet={4} gridMobile={2}>
        <VStack>
            <Group>
                <Image 
                    url='../assets/about/redeeming-special-needs-about-giggle-sweet.jpg'
                    />
                <Space.Content />
                <Caption1>Their giggle is any less sweet</Caption1>
            </Group>
        </VStack>
        <VStack>
            <Group>
                <Image 
                    url='../assets/about/redeeming-special-needs-about-smile-beautiful.jpg'
                    />
                <Space.Content />
                <Caption1>Their smile is any less beautiful</Caption1>
            </Group>
        </VStack>
        <VStack>
            <Group>
                <Image 
                    url='../assets/about/redeeming-special-needs-about-hands-capable.jpg'
                    />
                <Space.Content />
                <Caption1>Their hands are any less capable</Caption1>
            </Group>
        </VStack>
        <VStack>
            <Group>
                <Image 
                    url='../assets/about/redeeming-special-needs-about-free-spirit.jpg'
                    />
                <Space.Content />
                <Caption1>Their spirit is any less free</Caption1>
            </Group>
        </VStack>
    </HStack>
</Section>

</>)
}

export default Overview