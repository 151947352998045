import React from 'react'
import { Container, Section, Space, Title1, Body } from '@themeable/react-components'

function NotFoundPage() {
  return (
    <>
    <Section>
        <Container>
            <span style={{textAlign: 'center'}}>
                <Title1>404</Title1>
                <Space.NewSection/>
                <Body>Page not found. Looks like my husband messed this page up... Gotta go get him to fix it!</Body>
            </span>
        </Container>
    </Section>
    </>
  )
}

export default NotFoundPage