import React from 'react'

import './Sheet.css'

function Sheet({children, variant}) {
  return (
    <div className={`sheet${variant ? ` ${variant}`:''}`}>
        {children}
    </div>
  )
}

export default Sheet